.RankedItem_Wrapper {
    position: relative;
}

span.RankedItem_Rank {
    position: absolute;
    left: 0;
    background: #F3F4F6;
    border-radius: 8px 0px;
    color: black;
    padding: 0px 8px;
    font-size: 13px;
    z-index: 1;
}
.dark span.RankedItem_Rank{
    color: black;
}

.Rankings_TopRankedWrapper span.RankedItem_Rank {
    padding: 5px 15px;
    font-size: 1rem;
}


.RankedItemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
   
}

.RankedItemContent_WithIcon {
    padding-right: 1rem;
    border-radius: 8px; 
    border-radius: 8px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 15%); 
}

.dark.RankedItemContent {
    background: #4B5563;
}

.RankedItemContent_LeftSection {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    /* padding: 5px 0px 5px 5px */
}


.RankedItemContent .RankedItem_ImgWrapper {
    position: relative;
    width: 70px;
    height: 65px;
}

.RankedItemContent .RankedItem_ImgWrapper::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 0 0px 10rem rgb(0 0 0 / 0.25);
    -moz-box-shadow: inset 0 0 0px 10rem rgb(0 0 0 / 0.25);
    -webkit-box-shadow: inset 0 0 0px 10rem rgb(0 0 0 / 0.25);
    width: 100%;
    border-radius: 8px;
    width: -webkit-fill-available;
}

.RankedItemContent .RankedItem_ImgWrapper .BackgroundImage {
    border-width: 0px;
    border-radius: 8px;
}

.RankedItem_Title {
    color:#374151;
    font-size: 0.9rem;
}

.dark.RankedItem_Title {
    color:#F9FAFB
}