.ModalBasic {
  display: flex;
  justify-content: center;
}
.Modal_Overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* opacity: $overlay-opacity;
  background-color: $overlay-primary;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms; */
}
