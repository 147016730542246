/* * {
box-sizing : border-box;
border-radius: 8px;
} */

/* body {
margin : 50px 0 0;
text-align : center;
font-family : roboto;
background-color: #f6f8fa;

} */

.auto {
  width: 350px;

  display: inline-block;
}

.auto > div {
  width: 100%;
  /* position: relative; */
}

.auto input {
  padding: 12px 15px;
  font-size: 14px;
  width: 100%;
  color: black;
}

.dark .auto input {
  color: rgba(209, 213, 219, var(--tw-text-opacity));
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.auto input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
}

.auto .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  color: #0069ff;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
  /* z-index: 10; */
}

.auto .item {
  display: block;
  cursor: pointer;

  font-size: 14px;
  /* padding: 15px; */
}

.auto .item.selected-item {
  background-color: #0069ff;
  color: #fafbfc;
}

.auto .item:hover {
  background-color: #0069ff;
  color: #fafbfc;
}

* {
  -ms-overflow-style: none;
}

.AutoComplete_RankedItem .RankedItemContent .RankedItem_ImgWrapper {
  height: 40px;
  width: 50px;
}

.AutoComplete_RankedItem .RankedItemContent {
  background: none;
}

.AutoComplete_RankedItem .dark.RankedItemContent {
  background: none;
}

.AutoComplete .RankedItem_ImgWrapper {
  height: 45px;
  width: 55px;
}
