@import "../../../Assets//Styles/variables";


.UploadPicture {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.UploadPicture_Label{
  font-weight: bold;
  text-align: left;
}

.PhotoDropzone {
  width: 100%;
  align-self: center;
  height: 10rem;
  position: relative;
  background-color: inherit;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhotoDropzone-default {
  background: linear-gradient(0deg, #E5E5E5, #E5E5E5);
}

// TODO import width and height from variable.scss
.PhotoDropzone img {
  width: 100%;
  max-width: 30rem;
  height: 8rem;
  max-height: 8rem;
}

.PhotoDropzoneIcon {
  width: 3.5rem;
  height: 3rem;
}

.UploadIamgeIcon {
  width: 100%;
  height: 100%;
}
