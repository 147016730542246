

.Login_Card {
    width: 30%;
    height: 70.25%
}
.Forgot_Password_Card{
    width: 30%;
    height: 65%
}
.Login_Content{
    height:82%;
}