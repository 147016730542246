@import "../../../Assets//Styles/variables";

.UploadButton {
  position: $btn-upload-position;
  bottom: $btn-upload-position-bottom;
  right: $btn-upload-position-right;
  display: $btn-upload-display;
  width: $btn-upload-dimension;
  height: $btn-upload-dimension;

  &:hover {
    width: $btn-upload-dimension-hover;
    height: $btn-upload-dimension-hover;
  }
}

.UploadButton input {
  display: none;
}

// .UploadBtn_IconWrapper, svg{
//   display: block;
//   width: inherit;
//   height: inherit;
//   width: 100%;
// }

// .UploadBtn_IconWrapper svg {
//   width: 100%;
// }