@import "../../../Assets/Styles/variables";

.Accordian {
  &:nth-child(n){
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &:first-child{
    margin-top: 0rem;
  }
  &:last-child{
    margin-bottom: 0rem;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Accordian_Heading {
  display: $accordian-heading-display;
  align-items: $accordian-heading-align;
  width: $accordian-heading-width;
  cursor: pointer;
  padding: 0rem $accordian-heading-leftright-padding;
}

.AccordianHeading_LeftSection {
  display: $accordian-heading-ls-display;
  align-items: $accordian-heading-ls-align;
  gap: $accordian-heading-ls-gap;
  width: calc(100% - #{$accordian-control-icon-width});
}

.AccordianHeading_ControlIcon svg{
  color: $accordian-control-icon-color;
  width: $accordian-control-icon-width;
}

.AccordianTitle_Icon {
  width: $accordian-ls-icon-dimension;
  height: $accordian-ls-icon-dimension;
}

.AccordianTitle_Title {
  text-align: left;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Accordian_List {
  display: $accordian-list-display;
}

.AccordianListItem {
  display: flex;
  align-items: center;
}

.AccordianListItem_LS {
  width: calc(100% - 1.25rem);
  text-align: left;
  color: black;
  font-size: 1rem;
}

.AccordianListItem_Control {
  width: 1.25rem;
}

.CollapsedAccordian {
  .Accordian_Heading {
    // background-color: $accordian-heading-selected;
    padding: 0.5rem $accordian-heading-leftright-padding;
  }
  .AccordianHeading_ControlIcon {
    width: $accordian-collapsed-control-icon-width;
    color: $accordian-collapsed-control-icon-color;
  }
  .Accordian_List {
    display: $accordian-list-collapsed-display;
    flex-direction: $accordian-list-collapsed-direction;
    gap: $accordian-list-collapsed-gap;
    padding-left: $accordian-list-collapsed-padding-left;
    padding-right: $accordian-list-collapsed-padding-right;
  }
}
