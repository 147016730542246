.Docs_Wrapper {
  height: 98%;
}
.Content_Wrapper {
  height: 100%;
}

.Editor_Main_Wrapper {
  height: 90%;
  overflow-y: auto
}
.Editor-header {
  background-color: #282c34;
  /* min-height: 5vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* margin-bottom: 5vh; */
  text-align: center;
  
}
.wrapper-class {
  height: 95%;
  overflow:none
  /* padding: 1rem; */
  /* border: 1px solid #ccc; */
}
.editor-class {
  border-radius: .5rem;
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  overflow-y: auto;
}
.toolbar-class {
  border: 1px solid #ccc;
}
