@import "../../../Assets//Styles/variables";
@import "../../../Assets/Styles/functions.scss";

.TheAddInterestsModal .Modal_ContentWrapper {
  width: 30rem;
  background-color: white;
  border-radius: $border-radius-primary;
  padding: 2rem;
  text-align: left;
  font-size: 1.2rem;
  .Button {
    width: 100%;
  }
}

.TheAddInterestsModal .ModalHeading {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.25rem;
}

.TheAddInterestsModal .ModalBody {
  height: 25rem;
  overflow-y: auto;
}

.TheAddInterestsModal .ModalFooter {
  padding-top: 1.25rem;
}