.Main_Wrapper {
    max-height: calc(100vh - 5px);

}

.Main_Content_Wrapper {
    height: 100%;
    background-color:#E5E5E5
}

