
.RankList_HeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0rem;
}

.RankListHeader_SearchWrapper {
    width: 65%;
    border: 0.5px solid #D1D5DB;
    box-sizing: border-box;
    border-radius: 6px;
}

.RankList_AddBtn {
    display: flex;
    gap: 1rem;
    margin-right: 4rem;
    color: #374151;
}

.dark .RankList_AddBtn{
    color: #FFFFFF;
}

.RankList_HeaderWrapper {
    height: 4rem;
}

.RankList_FooterWrapper {
    height: 4rem;
}

.RankList_Rankings {
    height: 33rem;
    max-height: 33rem;
}

.RankList_FooterWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RankList_Rankings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 4rem;
    overflow-y: auto;
}

.RankList_Rankings .RankedItem_Wrapper {
    width: 30rem;
    max-width: -webkit-fill-available;
}

.RankList_BtnWrapper {
    display: flex;
    justify-content: space-between;
}

.TheAddItemModal {
    padding: 2rem;
}

.TheAddItemModal_Wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
