//General Colors
$white-900: #FFFFFF;
$white-800: #F9FAFB;
$white-700: #F3F4F6;
$white-600: #E5E5E5;
$white-500: #E5E7EB;

$dark-900: #0B0B0B;

$dark-blue-900: #111827;
$dark-blue-800: #1F2937;

$gray-700: #374151;
$gray-600: #4B5563;
$gray-500: #6B7280;
$gray-400: #9CA3AF;
$gray-300: #D1D5DB;
$gray-200: #CBD1DA;

$blue-700: #0e5be9;
$blue-600: #0e74e9;
$blue-500: #0284C7;
$blue-400: #0EA5E9;
$blue-300: #0ebde9;

$follow-icon-blue: #589BD4;

$danger: #dc2626;
$alert: #F87171;

//Backgrounds
$bg-page-primary: $white-800;
$bg-card-primary: $white-700;
$bg-card-header-primary: $dark-blue-800;
$bg-card-footer-primary: $dark-blue-800;

$dark-bg-page-primary: $dark-blue-900;
$dark-bg-page-secondary: $dark-900;
$dark-bg-card-primary: $dark-blue-900;
// $dark-bg-card-primary: $gray-600;


// overlay colors
$overlay-opacity: 0.65;
$overlay-primary: $gray-500;
$dark-overlay-primary: $dark-900;

// main layout
$main-layout-display: grid;
$main-layout-gap: 5rem;
$padding-leftright-main-layout: 8rem;
$padding-topbottom-main-layout: 1.5rem;
$main-layout-navbar: $white-900;
$dark-main-layout-navbar: $dark-blue-800;
// border radius
$border-radius-primary: 6px;
$border-radius-full: 17px;

//Font Styles
$link-color: $blue-400;
$span-primary: $gray-700;
$label-primary: $gray-700;
$heading-primary: $gray-700;
$subheading-primary: $gray-400;
$text-primary: $gray-700;
$text-secondary: $gray-500;
$text-alert: $danger;

$dark-link-color: $link-color;
$dark-heading-primary: $white-800;
$dark-subheading-primary: $gray-300;
$dark-span-primary: $white-900;
$dark-label-primary: $white-900;
$dark-text-primary: $white-800;
$dark-text-secondary: $gray-300;

// Badge styles
$badge-primary-bg: linear-gradient(270deg, #19A5D3 50%, #50D0D5 119%);
$badge-primary-color: $white-900;

// Date Picker style
$picker-height: 2.5rem;
$picker-padding-x: 1rem;
$picker-padding-y: 0.5rem;
$picker-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
$picker-box-shadow-hover: none;
$picker-box-sizing: border-box;
$picker-radius-fullrounded: $border-radius-full;
$picker-radius-semirounded: $border-radius-primary;

$picker-font-size: 0.875rem;
$picker-font-weight: 600;
$picker-focus-box-shadow: none;


// Button styles
$btn-height: 2.5rem;
$btn-padding-x: 1rem;
$btn-padding-y: 0.5rem;
$btn-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
$btn-box-shadow-hover: none;
$btn-box-sizing: border-box;
$btn-radius-fullrounded: $border-radius-full;
$btn-radius-semirounded: $border-radius-primary;

$btn-font-size: 0.875rem;
$btn-font-weight: 600;
$btn-focus-box-shadow: none;

$btn-primary: $blue-400;
$btn-primary-color: $white-900;
$btn-primary-hover: $blue-500;
$btn-primary-focused: $blue-400;
$btn-primary-outline: $blue-600;

$btn-secondary: $white-900;
$btn-secondary-color: $gray-700;
$btn-secondary-hover: $white-700;
$btn-secondary-click: $white-900;
$btn-secondary-outline: $white-900;

$btn-alert: $alert;
$btn-alert-color: $white-900;

$btn-border-width: 1px;
$btn-border-primary-style: solid;
$btn-border-primary-color: $blue-300;
$btn-border-secondary-color: $gray-300;

$btn-primary-link: transparent;
$btn-primary-link-hover: transparent;
$btn-primary-link-click: transparent;
$btn-primary-link-color: $blue-400;
$btn-primary-link-color-secondary: $gray-400;

$btn-disabled: $gray-400;
$btn-disabled-color: $white-900;
$btn-disabled-border-color: transparent;
$btn-disabled-opacity: 0.9;

// Upload Button styles
$btn-upload-display: block;
$btn-upload-dimension: 2.75rem;
$btn-upload-dimension-hover: 2.95rem;
$btn-upload-position: absolute;
$btn-upload-position-bottom: 0%;
$btn-upload-position-right: 2%;
$btn-upload-z-index: 20;

// Navigation styles
$nav-primary-link: transparent;
$nav-primary-link-hover: $white-900;
$nav-primary-link-click: $white-800;
$nav-primary-link-color: $gray-700;

$dark-nav-primary-link: transparent;
$dark-nav-primary-link-hover: $gray-600;
$dark-nav-primary-link-click: $gray-700;
$dark-nav-primary-link-color: $gray-300;

$ls-nav-item-width: 15rem;

// FormField styles
$dark-input-primary: $gray-600;


// Accordian styles
$accordian-heading-display: flex;
$accordian-heading-align: center;
$accordian-heading-width: 100%;
$accordian-heading-selected: $gray-600;
$accordian-heading-leftright-padding: 1.25rem;

$accordian-heading-ls-display: flex;
$accordian-heading-ls-align: center;
$accordian-heading-ls-gap: 1.5rem;

$accordian-ls-icon-dimension: 1.5rem;

$accordian-control-icon-width: 1rem;
$accordian-control-icon-color: $dark-text-primary;

$accordian-collapsed-control-icon-width: 1rem;
$accordian-collapsed-control-icon-color: $gray-400;

$accordian-list-display: none;
$accordian-list-collapsed-display: flex;
$accordian-list-collapsed-direction: column;
$accordian-list-collapsed-gap: 0.75rem;
$accordian-list-collapsed-padding-left: $accordian-heading-leftright-padding + $accordian-ls-icon-dimension + $accordian-heading-ls-gap;
$accordian-list-collapsed-padding-right: 1rem;

// Layouts Breakdown
$navbar-height: 72px;
$layout-gap-primary: 2rem;
$page-layout-gap: 1rem;
$page-header-height: 30px;
$tabs-height: 6rem;

// Others
$z-index-10: 10;
$z-index-20: 20;
$z-index-30: 30;
$z-index-40: 40;
$z-index-50: 50;
$z-index-60: 60;