.FAQs_Main_wrapper{
    height:100%;
}
.FAQS_First_Coulnm {
  width: 30%;
  
}
.FAQS_Second_Coulnm {
  width: 67%;
}
.FAQS_Content_Wrapper {
    height: 84%;
}