.sapphire {
  color: #001848;
}
.raven {
  color: #6b7280;
}
.primary_black {
  color: #374151;
}
.bg_solitude {
  background-color: #f3f4f6;
}
.bg_red {
  background-color: #f87171;
}
.bg_skyblue {
  background-color: #246bb4;
}
