.react-time-picker__wrapper {
    display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid lightgray;
  line-height: 1.95;
  color: black;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.dark.TimePicker_Input.react-time-picker{
  background-color: #2F2F2F;

}
.react-time-picker__inputGroup {
  color: black;
}
.react-time-picker__inputGroup__leadingZero{
  margin-top: -1px;
}
.dark.react-time-picker__inputGroup {
  color: white;
}
.react-time-picker__inputGroup__input{
  outline:none;
}
.react-time-picker__inputGroup__amPm {
  background-color: transparent;
}
.dark.react-time-picker__inputGroup__amPm {
  background-color: #2F2F2F;
}
.react-time-picker__button svg {
    stroke: currentColor;
    color: #9298a9;
    width: 19px;
    height: 19px;
  }
  