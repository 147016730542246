@import "../../../Assets/Styles/variables";


span.badge {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 2px 20px;
    background: $badge-primary-bg;
    color: $badge-primary-color;
    border-radius: 12px;
}