.Main_Dashboard_Wrapper {
    height: 100%;

}

.Main_Grid {
    height: 95%;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
}
.Dashboard_Wrapper{
    height: 50rem
}
.Pickems_Wrapper {
    grid-row: span 2;
}
.Pickems_Graph {
    min-height: 90%
}
/* .Pickem_Main_Wrapper{
    height: 35.5rem;
} */
.Demographic_Wrapper {
    grid-row: span 2;
}

.Demographic_Graph {
    min-height: 85%
}
.Dashboard_First_col{
    width: 58%;
}

.Mischka{
    color: #9CA3AF
}
.DarkBlue {
    color:#194890
}
.LightBlue {
    color:#589BD4
}
.Gray {
    color:#9CA3AF;
}
.LightGray {
    color: #D1D5DB;
}