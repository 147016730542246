@import "../../Assets/Styles/variables";
@import "../../Assets/Styles/functions.scss";

.DetailForm_ContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.DetailForm_ContentWrapper {
    height: 37rem;
    max-height: 37rem;
}

.DetailForm_FooterWrapper {
    height: 4rem;
}

.DetailForm_InputField {
    width: 65%;
}

.DetailForm_OptionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.DetailForm_OptionsWrapper span {
    text-align: left;
}

.DetailForm_Options {
    display: flex;
    flex-direction: column;
}

.DetailForm_FooterWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Footer_BtnWrapper {
    display: flex;
    justify-content: flex-end;
}

.DetailForm_AddInterestsSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.DetailForm_AddInterests {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    min-width: 65%;
    max-width: 100%;
    min-height: 1.04875rem;
    max-height: rem(105px);
    overflow-y: auto;
    padding: 1rem;
    border-radius: $border-radius-primary;
    color: black;;
}
.dark .DetailForm_AddInterests {
    background-color: black;
    color: blanchedalmond;
}

.DetailForm_AddInterests span.badge {
    border-radius: $border-radius-full;
    cursor: default;
}

.RankpageDetail_Description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
}