.Rankpage_Form_Height {
  height: 90%;
}
.Rankpage_First_Coulnm{
  width: 30%;
  height: 88%;

}
.Rankpage_Second_Coulnm {
  width: 67%;
  height: 88%;
}
